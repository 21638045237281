/* eslint-disable camelcase */
import Cookies from 'js-cookie';
import CurrentCountry from '@phoenix/components/CurrentCountry';
import ProductData from '@phoenix/search/interfaces/ProductData';
import isPlainObject from 'lodash-es/isPlainObject';
import isObject from 'lodash-es/isObject';

const CHECKOUT_STEPS_COOKIE_NAME = 'checkoutTrackingSteps';

interface CurrencyArray {
  [key: string]: string;
}

export default abstract class Tracking {
  pageProductTrackingInformation: object;
  pageType: string;
  pageCountry: string;
  pageLanguage: string;
  currency: string;
  currencies: CurrencyArray[];
  userHasAcceptedCookieConsent: boolean;
  pageDataLayer: object;
  intersectionObserver: IntersectionObserver | null = null;

  public constructor() {
    this.pageProductTrackingInformation = window.Phoenix.trackingVariables.pageProductTrackingInformation;
    this.pageType = window.Phoenix.trackingVariables.pageType;
    this.pageCountry = window.Phoenix.trackingVariables.pageCountry;
    this.pageLanguage = window.Phoenix.trackingVariables.pageLanguage;
    this.currency = window.Phoenix.trackingVariables.currency;
    this.currencies = window.Phoenix.trackingVariables.currencies;
    this.pageDataLayer = window.Phoenix.trackingVariables.pageDataLayer;
    this.userHasAcceptedCookieConsent = false;

    const currentCountry = new CurrentCountry();
    currentCountry.update();

    if (this.pageProductTrackingInformation && Object.keys(this.pageProductTrackingInformation).length > 0) {
      this.setProductPageTracking();
    }

    this.bindEventListeners();

    this.pushUserContext();
  }

  public abstract pushEvent(event: object): void;

  /**
   * This function is used to format parameters starting with an underscore to use the current country code
   * and make sure we have correct information such as prices and currency.
   * @param data
   */
  public formatWithDynamicData(data: object): object {
    const keys = Object.keys(data);
    // First, we search for keys starting with an underscore and we replace them with the dynamic value
    keys.forEach((key) => {
      if (!key.startsWith('_')) return;

      // TODO: Change how we get the current country to make sure we have the correct one
      const dynamicValue = data[key][window.Phoenix.currentCountry] ?? null;
      if (dynamicValue === null) return;

      delete data[key];
      data[key.substring(1)] = dynamicValue;
    });

    // Then, we need to check if we have an object or array to format them recursively
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (isPlainObject(value)) {
        data[key] = this.formatWithDynamicData(value);
      } else if (Array.isArray(value)) {
        data[key] = value.map((v) => (isObject(v) ? this.formatWithDynamicData(v) : v));
      }
    });

    return data;
  }

  public pushEvents(events: object[]): void {
    events.forEach((event) => this.pushEvent(event));
  }

  public hasUserAcceptedCookieConsent(): boolean {
    return this.userHasAcceptedCookieConsent;
  }

  public setUserHasAcceptedCookieConsent(accepted: boolean): void {
    this.userHasAcceptedCookieConsent = accepted;
  }

  public formatWithCurrencies(trackingInformation: object): object {
    if (this.currencies.length <= 1) {
      return trackingInformation;
    }
    trackingInformation._currency = this.currencies;
    return trackingInformation;
  }

  protected getIntersectionObserver(): IntersectionObserver {
    if (this.intersectionObserver !== null) {
      return this.intersectionObserver;
    }

    this.setIntersectionObserver();

    return this.intersectionObserver;
  }

  protected setIntersectionObserver(): void {
    this.intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }

        this.pushEvent(JSON.parse(entry.target.dataset.trackingVisible));
        this.getIntersectionObserver().unobserve(entry.target);
      });
    });
  }

  public observeVisibility(element): void {
    this.getIntersectionObserver().observe(element);
  }

  public abstract setProductPageTracking(): void;

  public updateCheckoutPageView(step): void {
    // Empty & Order steps should have the normal checkout URL
    const viewTypes = ['', '', 'delivery', 'billing', 'payment'];
    const viewType = viewTypes[step] ?? null;
    if (viewType === null) {
      return;
    }

    // We need to find the first element that has a pg_uri value
    const dataLayerIndex = window.dataLayer.findIndex((item) => item['pg_uri']); // eslint-disable-line

    if (dataLayerIndex !== -1) {
      // eslint-disable-next-line
      window.dataLayer[dataLayerIndex]['pg_uri'] =
        window.location.href.split('?')[0] + (viewType !== '' ? `-${viewType}` : '');
    }
  }

  /**
   * When the user add a product to the Wishlist
   * @PhoenixID all_rKPI_P4.1
   */
  public abstract getWishlistTracking(productTracking): object;

  public getShareWishlistTracking(): object {
    return { event: 'share_wishlist' };
  }

  /**
   * When a user clicks on the CTA button "Try in boutique" or BSAOD
   * @PhoenixID all_product_page_bsaod
   */
  public abstract getProductPageBsaodTracking(boutiqueId, boutiqueName): object;

  /**
   * Click on submit to receive a back in stock alert by email
   * @PhoenixID all_rKPM_P7
   */
  public abstract getBackInStockTracking(): object;

  /**
   * When a user successfully subscribe to newsletter.
   * @PhoenixID all_rKPI_U1
   */
  public abstract async getNewsletterSubscriptionTracking($email): Promise<object>;

  /**
   * When a user clicks on one of the icons of the sticky header menu
   * @PhoenixID all_rKPM_C4.1
   */
  public abstract getHeaderLinkTracking(link): object;

  /**
   * When a user clicks on a filter in a mixed grid
   * @PhoenixID all_rKPM_C7
   * @param filter
   * @param subFilter
   */
  public abstract getFilterTracking(filter, subFilter): object;

  /**
   * When user clicks on "View Results" button
   * @PhoenixID all_product_finder_view_results
   */
  public getProductFinderViewResultsTracking(): object {
    return { event: 'productfinder_result' };
  }

  /**
   * When a user clicks on "close" button
   * @PhoenixID all_product_finder_close
   */
  public getProductFinderCloseTracking(): object {
    return { event: 'productfinder_closed' };
  }

  /**
   * When user clicks on "View Results" button
   * @PhoenixID all_search_filter_view_results
   */
  public abstract getSearchFilterViewResultsTracking(term: string, filter: string): object;

  /**
   * When a user makes a global site search
   * @PhoenixID all_rKPM_C3.1
   */
  public abstract getSearchResultsTracking(searchTerm, nbSearchResults): object;

  /**
   * When a user click on a result of a search to display the PDP/content/manual
   * @PhoenixID all_rKPM_C3.2
   */
  public abstract getSearchResultsLinkTracking(searchTerm, nbSearchResults, searchClicked): object;

  /**
   * When a user search for a boutique
   * @PhoenixID all_rKPM_O3.1
   */
  public abstract getStoreSearchTracking(searchTerm): object;

  /**
   * When a user submits a contact request via the contact form
   * @PhoenixID all_contact_form
   */
  public abstract async getContactFormTracking(contactMode, contactReason, userEmail): Promise<object>;

  /**
   * When a user successfully sent a boutique appointment
   * @PhoenixID all_rKPI_O2
   */
  public abstract async getBoutiqueAppointFormTracking(
    appointmentId,
    boutiqueId,
    visitPurpose,
    userEmail
  ): Promise<object>;

  /**
   * When a user successfully create an account
   * @PhoenixID all_rKPI_U2
   */
  public abstract async getAccountCreationFormTracking(userEmail): Promise<object>;

  /**
   * When a user successfully logs in on the website
   * @PhoenixID all_rKPI_U3
   */
  public abstract async getLoginFormTracking(userEmail): Promise<object>;

  /**
   * When a user sent a guaranty extension form
   * @PhoenixID rdu_guarantee_extension_sent
   */
  public abstract getGEPFormTracking(): object;

  /**
   * When the user successfully submit the sales barometer form
   * @PhoenixID rdu_sales_barometer
   */
  public abstract getSalesBarometerTracking(): object;

  /**
   * When the user click on affirm "apply know" button on the product detail page - US only
   * @PhoenixID all_affirm_apply_now
   */
  public abstract getAffirmTracking(): object;

  /**
   * When the user clicks on the CTA button "Add to cart"
   * @PhoenixID all_rKPI_P5.1
   */
  public abstract getAddToCartTracking(): object;

  /**
   * When the user remove product to the basket
   * @PhoenixID all_rKPI_P5.2
   * @param item
   */
  public abstract getRemoveFromCartTracking(item): object;

  /**
   * When we select "BOUTIQUE PICK UP" radio button and select a boutique
   * @PhoenixID all_checkout_boutique_pickup
   */
  public abstract getCheckoutBoutiquePickUpTracking(boutiqueId, boutiqueName, items): object;

  /**
   * When a gift message is added
   * @PhoenixID all_rKPM_P11
   */
  public getCheckoutGiftMessageTracking(productId): object {
    return {
      event: 'add_gift_message',
      item_id: productId,
    };
  }

  /**
   * (engraving confirmation submission) i.e. click on "SAVE" CTA
   * @PhoenixID all_rKPM_P6.2
   */
  public getCheckoutEngravingMessageTracking(items): object {
    return {
      event: 'engraved_item',
      items,
    };
  }

  /**
   * When the user is on the basket page
   * @PhoenixID all_rPKM_P6.1.1
   */
  public abstract getViewCartTracking(items, currency, userStatus): object;

  /**
   * When the user clicks on Checkout button to enter the checkout funnel
   * @PhoenixID all_rKPM_P6.1.2
   */
  public abstract getCheckoutStep1Tracking(items, currency, userStatus): object;

  /**
   * When the user entered is shipping info and clicks on next step
   * @PhoenixID all_rKPM_P6.1.3
   */
  public abstract getCheckoutStep2Tracking(items, currency, userStatus, shippingTier): object;

  /**
   * When user completed the billing section and click on get payments
   * @PhoenixID all_rKPM_P6.1.4
   */
  public abstract getCheckoutStep3Tracking(items, currency, userStatus, shippingTier): object;

  /**
   * When user completed the payment section and click on proceed to payment
   * @PhoenixID all_rKPM_P6.1.4
   */
  public abstract getCheckoutStep4Tracking(items, currency, userStatus, paymentType): object;

  /**
   * When a user is on a product list page and clicks on a product, we want to push the event beside in the dataLayer.
   * @PhoenixID all_rKPI_P2.2
   */
  public abstract getProductListClickTracking(item): object;

  /**
   * When a user is on a product list page and clicks on a product, we want to push the event beside in the dataLayer.
   * @PhoenixID all_rKPI_P2.2
   */
  public abstract getProductListClickTrackingFromSearchResult(product: ProductData): object;

  /**
   * When the user select greeting card option
   * @PhoenixID all_order_online_form_card
   */
  public abstract getLightEcomGiftWrappingTracking(items, shippingTier): object;

  /**
   * When the user select personal message
   * @PhoenixID all_order_online_form_message
   */
  public abstract getLightEcomGiftMessageTracking(items, shippingTier): object;

  /**
   * When the user select home delivery option
   * @PhoenixID all_order_online_form_home_delivery
   */
  public abstract getLightEcomHomeDeliveryCtaTracking(items, shippingTier): object;

  /**
   * When the user select boutique pickup option
   * @PhoenixID all_order_online_form_boutique_pickup
   */
  public abstract getLightEcomBoutiqueDeliveryCtaTracking(items, shippingTier): object;

  /**
   * When the user select boutique pickup option
   * @PhoenixID all_order_online_form_address_validated
   */
  public abstract getLightEcomHomeDeliveryFormTracking(items, shippingTier): object;

  /**
   * When the boutique for pickup is selected
   * @PhoenixID all_order_online_form_boutique_pickup_validated
   */
  public abstract getLightEcomBoutiqueDeliveryFormTracking(items, shippingTier): object;

  /**
   * When the user click on use the same address for billing
   * @PhoenixID all_order_online_form_same_billing
   */
  public abstract getLightEcomSameBillingTracking(items, shippingTier): object;

  /**
   * When the user submit the billing address
   * @PhoenixID all_order_online_form_other_billing
   */
  public abstract getLightEcomBillingFormTracking(items, shippingTier): object;

  /**
   * When the user click on submit the order
   * @PhoenixID all_order_online_form_submitted
   */
  public abstract getLightEcomSubmitTracking(items, shippingTier): object;

  /**
   * When items from a product list are displayed
   * @PhoenixID all_rKPI_P2.1
   */
  public abstract getProductListDetailsTrackingEvents(items: object[], listName: string, listId?: string): object[];

  /**
   * @PhoenixID all_password_forgot
   */
  public abstract getPasswordForgotTracking(): object;

  /**
   * When a product list is displayed
   * @PhoenixID all_rKPI_P2.1
   */
  public getProductListTracking?(listName: string, listId?: string): object;

  public getCustomEventTracking(event, parameters = {}): object {
    parameters.event = event;
    return parameters;
  }

  public addCheckoutStepEvent(step, tracking): void {
    const countryCode = window.Phoenix.defaultCountryCode;
    // First we check if the step has already been pushed
    const checkoutSteps = JSON.parse(Cookies.get(`${CHECKOUT_STEPS_COOKIE_NAME}${countryCode}`) || '[]');
    if (checkoutSteps.includes(step)) {
      return;
    }
    // If the step has not been pushed, we push it and also in a cookie
    this.pushEvent(tracking);
    checkoutSteps.push(step);
    Cookies.set(`${CHECKOUT_STEPS_COOKIE_NAME}${countryCode}`, JSON.stringify(checkoutSteps), {
      secure: true,
      expires: 1 / 12, // 2 hours
    });
  }

  public reinitCheckoutSteps(): void {
    const countryCode = window.Phoenix.defaultCountryCode;
    Cookies.remove(`${CHECKOUT_STEPS_COOKIE_NAME}${countryCode}`);
  }

  protected async sha256(message): Promise<string> {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    // convert bytes to hex string
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  }

  public getGiftingGuideTracking(): object {
    return {
      event: 'gifting_guide',
    };
  }

  public getGiftingGuideStepTracking(step, cta): object {
    const eventStep = `gifting_guide_step_${step}`;
    return {
      event: eventStep,
      guifting_guide_cta: cta,
    };
  }

  public getGiftingGuideResultTracking(item_quantity, items): object {
    return {
      event: 'gifting_guide_result',
      guifting_guide_item_quantity: item_quantity,
      items,
    };
  }

  public getStartingEngravingTracking(cta): object {
    return {
      event: 'strating_engraving',
      engraving_cta: cta,
    };
  }

  public getEngravingSearchReferenceTracking(): object {
    return {
      event: 'engraving_search_reference',
    };
  }

  public getEngravingFindReferenceTracking(): object {
    return {
      event: 'engraving_find_reference',
    };
  }

  public getEngravingSelectReferenceTracking(itemId, itemName): object {
    return {
      event: 'engraving_select_reference',
      item_id: itemId,
      item_name: itemName,
    };
  }

  public getEngravingTypeSelectedTracking(itemId, itemName, engravingType, engravingColor): object {
    return {
      event: 'engraving_type_selected',
      item_id: itemId,
      item_name: itemName,
      engraving_type: engravingType,
      engraving_color: engravingColor,
    };
  }

  public getEngravingMonumentSelectedTracking(itemId, itemName, engravingType): object {
    return {
      event: 'engraving_monument_selected',
      item_id: itemId,
      item_name: itemName,
      engraving_type: engravingType,
    };
  }

  public getEngravingEstimationFinalisedTracking(itemId, itemName): object {
    return {
      event: 'engraving_estimation_finalised',
      item_id: itemId,
      item_name: itemName,
    };
  }

  public getEngravingReceiveMyEstimateTracking(itemId, itemName): object {
    return {
      event: 'receive_my_estimate',
      item_id: itemId,
      item_name: itemName,
    };
  }

  public getSignInModalPageViewTracking(): object {
    return this.formatWithCurrencies({
      event: 'page_view',
      page: `/${this.pageCountry}-${this.pageLanguage}/pop-in/account/sign-in`,
      user_status: '',
      user_id: '',
      pg_type: this.pageType,
      user_email: '', // It makes no sens as the user has not entered any email for now
      currency: this.currency,
    });
  }

  public getCountryPropositionTracking(countryCode: string): object {
    return {
      event: 'country_selector',
      country_selected: countryCode,
    };
  }

  public getFieldTrackingEvent(field: string, form: string): object {
    return {
      event: 'field_focus',
      field,
      form,
    };
  }

  // pushing user_context (look for user_context in https://agile.richemont.com/confluence/pages/viewpage.action?pageId=176463295)
  public async pushUserContext(): Promise<void> {
    if (!window.dataLayer) {
      return;
    }

    const pingResult = JSON.parse(localStorage.getItem(`pingResult${window.Phoenix.currentCountry}`));
    let userDataLayer: object = {
      event: 'user_context',
      ...this.pageDataLayer,
      user_status: 'notLogged',
      pg_country: window.Phoenix.currentCountry,
    };
    if (pingResult?.data?.customerInfo?.id) {
      const customerInfo = pingResult?.data?.customerInfo;
      userDataLayer = {
        ...userDataLayer,
        user_status: 'logged',
        user_id: customerInfo.id,
        user_id_type: 'SWSE_ID',
        user_email: await this.sha256(customerInfo.email),
      };
    }
    window.dataLayer.push(userDataLayer);
  }

  public getStrapSizeFinderSelectSizeEvent(size: string): object {
    return {
      event: 'strap_find_my_size',
      wrist_size: size,
    };
  }

  public getStrapFinderEvent(reference, available): object {
    return {
      event: 'strap_finder',
      strap_reference: reference,
      evt_item_available: available,
    };
  }

  public getSalesForceGetNotifiedTracking(): object {
    return {
      event: 'get_notified',
    };
  }

  public getClientReviewsLoadMoreTracking(): object {
    return {
      event: 'reviews_click',
      cta_title: 'load_more_reviews',
    };
  }

  public getClientReviewReadMoreTracking(): object {
    return {
      event: 'reviews_click',
      cta_title: 'AA_reviews_read_more',
    };
  }

  public getStoreLocatorLocationSelectedTracking(locationName, locationType, clickPosition): object {
    return {
      event: 'boutique_detail',
      boutique_name: locationName,
      boutique_type: locationType,
      click_position: clickPosition,
    };
  }

  protected abstract bindEventListeners(): void;
}
